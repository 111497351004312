.divider {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #808080;
}

.divider > span {
  position: relative;
  background-color: #ddd;
  padding: 0 5%;
  font-weight: 600;
  font-size: 1em;
  z-index: 1;
}
