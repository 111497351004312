.upgrade-modal {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	background-color: rgba(#000000, 0.25);
	display: flex;
	align-items: center;
	justify-content: center;

	* {
		&::-webkit-scrollbar {
			width: 8px;
			height: 8px;
		}
		&::-webkit-scrollbar-track {
			background: #f1f1f1;
		}
		&::-webkit-scrollbar-thumb {
			background: #888;
		}
		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
	}

	.mask {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.content {
		position: relative;
		z-index: 10;
		background-color: rgba(#ffffff, 1);
		width: 100%;
		height: 100%;
		padding: 0 2%;

		.heading {
			margin-bottom: 24px;

			> div {
				margin-bottom: 8px;

				.logo {
					max-height: 50px;
				}

				button {
					width: 30px;
					height: 30px;
					border: thin solid #4274c4;
					border-radius: 50px;
					color: #4274c4;
					background: none;
					outline: none;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
				}
			}

			h1 {
				color: #042954;
				font-weight: 700;
				font-size: 1.5em;
				letter-spacing: 0.125em;
			}
		}

		.body.desktop {
			display: none;
		}

		.body.mobile {
			.rc-collapse {
				.rc-collapse-item {
					margin: 8px 0;

					&:first-child {
						margin-top: 0;
					}
					&:last-child {
						margin-bottom: 0;
					}

					.rc-collapse-header {
						background-color: rgba(4, 41, 84, 0.1);

						.rc-collapse-header-text {
							text-align: center;
							color: #042954;
							margin: 0;
							font-size: 0.9em;
							font-weight: 600;
						}
					}

					ul {
						padding-left: 16px;

						li {
							font-size: 0.75em;
							margin: 0.25rem 0;
						}
					}

					&.current {
						border-color: #042954;

						.rc-collapse-header {
							background-color: #042954;

							.rc-collapse-header-text {
								color: #ffff00;
							}

							span {
								font-size: 0.75em;
							}
						}
					}
				}
			}
		}
	}

	@media (min-width: 578px) {
		.content {
			width: max-content;
			height: max-content;
			padding: 12px 20px 16px;
			max-width: 95%;
			max-height: 95%;
			border-radius: 12px;

			.heading {
				width: 100%;
				position: relative;
				margin-bottom: 32px;

				h1 {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					margin: 0;
					font-size: 1.75em;
				}
			}

			.body.mobile {
				display: none;
			}

			.body.desktop {
				display: grid;
				grid-template-columns: repeat(5, 1fr);
				gap: 12px;
				overflow-x: auto;

				> div {
					border: thin solid #808080;
					border-radius: 8px;
					width: 150px;
					height: 200px;

					> h2 {
						text-align: center;
						background-color: rgba(4, 41, 84, 0.1);
						color: #042954;
						padding: 8px 0;
						margin: 0;
						font-size: 0.9em;
						font-weight: 600;
					}

					ul {
						padding: 8px 0 8px 20px;

						li {
							font-size: 0.75em;
							margin: 0.25rem 0;
						}
					}

					&.current {
						border-color: #042954;

						> h2 {
							background-color: #042954;
							color: #ffff00;

							span {
								font-size: 0.75em;
							}
						}
					}
				}
			}
		}
	}
}
