/* code for help center page  */

.help-centre.close-btn {
	position: fixed;
	right: 0;
	top: 0;
	width: 30px;
	height: 30px;
	z-index: 6;
	margin: 20px;
	border: 2px solid rgb(25, 25, 138);
	border-radius: 50px;
	background: transparent;
	transition: 0.2s;
}

.help-centre.close-btn:hover {
	border: 2px solid rgb(196, 202, 255);
	border-radius: 50px;
	color: rgb(30, 33, 101);
}

.help-centre-container {
	z-index: 5;
	background-color: #f2f3f8;
	width: 100%;
	height: calc(100% + 70px);
	min-height: 800px;
	position: absolute;
	top: 0;
	left: 0;
}

.help-centre-header {
	position: absolute;
	top: 32px;
	left: 72px;
}

.help-centre-logo img {
	width: 185px;
}

.help-centre-welcome-section {
	position: absolute;
	top: 80px;
	margin: 0px 10%;
}

.help-centre-welcome-section h2 {
	font-size: 40px;
	font-weight: normal;
}

.help-centre-search {
	background-color: #f2f3f8;
	width: 784px;
	height: 64px;
	border-radius: 50px;
	position: relative;
	box-shadow: 2px 2px 5px #dadada;
	display: flex;
	justify-content: center;
	align-items: center;
}

.help-centre-search i {
	font-size: 35px;
	margin: 0px 20px;
	color: #4f4f4f;
}

.help-centre-search input {
	flex: 1;
	height: 100%;
	background-color: transparent;
	border: none;
	font-size: 19px;
	outline: none !important;
}

.help-centre-body {
	position: absolute;
	top: 320px;
	display: flex;
	justify-content: space-between;
	width: 80%;
	margin: 0px 10%;
}

.help-centre-body-details {
	margin-top: -20px;
}

.help-centre-body-details h3 {
	font-size: 28px;
	margin-bottom: 5px;
	color: #042954;
	font-weight: normal;
}

.help-centre-body-details a {
	font-size: 19px;
	color: #042954;
}

.help-centre-body-chat {
	background-color: #f2f3f8;
	height: 622px;
	width: 440px;
	border-top-right-radius: 20px;
	border-top-left-radius: 20px;
	box-shadow: -3px 0px 5px rgba(4, 41, 84, 0.15),
		3px 0px 5px rgba(0, 0, 0, 0.15);
}

.help-centre-body-chat-header {
	height: 82px;
	background-color: #042954;
	border-top-right-radius: 20px;
	border-top-left-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.help-centre-body-chat-header img {
	width: 121px;
	margin: 0px 20px;
}

.help-centre-body-chat-header div {
	flex: 1;
	color: white;
}

.help-centre-body-chat-header div p {
	font-size: 23px;
}

.help-centre-body-chat-header div p span {
	font-size: 16px;
}

.help-centre-body-chat-body-msg-left {
	background-color: white;
	width: 350px;
	height: 94px;
	margin: 30px;
	position: relative;
	padding: 10px 15px;
	box-sizing: border-box;
	border-radius: 5px;
}

.help-centre-body-chat-body-msg-left::before {
	content: '';
	bottom: 0;
	left: -15px;
	height: 20px;
	clip-path: polygon(
		100% 0,
		100% 9%,
		100% 35%,
		100% 100%,
		81% 100%,
		50% 100%,
		15% 100%,
		0 100%,
		0 82%,
		100% 0
	);
	width: 15px;
	background-color: white;
	position: absolute;
}

.help-centre-body-chat-body-msg-left b {
	font-size: 19px;
	font-weight: 600;
}

.help-centre-body-chat-body-msg-left span {
	font-size: 16px;
}

.help-centre-body-chat-body-input {
	height: 88px;
	width: 380px;
	background-color: white;
	display: block;
	margin: auto;
	bottom: 0;
	position: absolute;
	margin: 20px;
}

.help-centre-body-chat-body-input textarea {
	min-width: 100%;
	min-height: 100%;
	max-width: 100%;
	max-height: 100%;
	padding: 10px;
	border-radius: 3px;
	outline: none;
	border: 1px solid rgb(190, 190, 190);
	box-sizing: border-box;
	color: #042954;
}
