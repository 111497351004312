.map-view {
  /* marker styling */
  .leaflet-popup-content-wrapper {
    padding: 0 !important;
    overflow: hidden;
    border-radius: 0px;
  }

  .leaflet-popup-content {
    width: -moz-fit-content !important;
    width: fit-content !important;
    margin: 0 !important;
  }

  .leaflet-popup-content .popup-content {
    width: 160px;
  }

  .button-87 {
    border-radius: 5px;
  }

  .leaflet-popup-close-button {
    color: white !important;
    padding: 0px 2px 0 0 !important;
  }

  .leaflet-popup-content .project-info-popup {
    /* min-width: 200px; */
    width: 100%;
    padding: 0.75em;
  }

  .popup-content {
    padding: 0;
    display: flex;
    flex-direction: column;

    .img-section {
      width: 100%;
      height: 100px;
      background-color: #0a0a11;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      img.verified {
        position: absolute;
        top: 2px;
        left: 2px;
      }

      img:not(.verified) {
        height: 100%;
        width: 100%;
      }
    }

    .popup-info {
      /* height: 50%; */
      display: flex;
      padding: 0.5rem;
      background: #042954;
      color: white;
      position: relative;
      flex-direction: column;
      justify-content: space-between;
      gap: 0.25rem;
      width: 100%;

      div {
        display: flex;
        justify-content: space-between;
        font-size: 0.9em;
      }
    }

    a {
      width: 100%;
      font-size: 0.9em;
      padding-bottom: 0.05rem;
      padding-top: 0.15rem;
      font-weight: 600;
      margin-top: 0.5rem;
      display: block;
      text-align: center;
      color: white;
    }
  }

  .marker-cluster-custom-count {
    width: max-content !important;
    height: max-content !important;

    > div {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 2px solid white;
      color: white;
      position: relative;

      > span.count {
        font-size: 12px !important;
        font-weight: 600;
      }

      &:hover {
        > span:not(.count) {
          position: absolute;
          z-index: -1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: inherit;
          border-radius: 50%;
          transform-origin: 0 0;
          animation: recent-scaling 2s infinite;

          &:nth-child(1) {
            width: 180%;
            height: 180%;
            opacity: 30%;
          }

          &:nth-child(2) {
            width: 240%;
            height: 240%;
            opacity: 20%;
          }
        }
      }
    }
  }

  // .marker-cluster-custom-dots {
  //   width: 30px !important;
  //   height: 30px !important;
  //   div {
  //     position: relative;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     width: 100% !important;
  //     height: 100% !important;
  //     /* border: thin solid !important; */

  //     .pox {
  //       position: absolute;
  //       width: 5px;
  //       height: 5px;
  //       border-radius: 50%;
  //     }
  //   }
  // }

  .marker-custom {
    width: max-content !important;
    height: max-content !important;

    div.pox-marker {
      position: relative;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.25s linear;
      border-radius: 50%;
      border: 2px solid white;

      &.recent {
        position: relative;
        border: none;

        > div.recent-anim {
          > span {
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: inherit;
            border-radius: 50%;
            transform-origin: 0 0;
            animation: recent-scaling 2s infinite;

            &:nth-child(1) {
              width: 180%;
              height: 180%;
              opacity: 30%;
            }

            &:nth-child(2) {
              width: 240%;
              height: 240%;
              opacity: 20%;
            }
          }
        }
      }

      &:hover {
        transform: translateY(-3px);

        &::before {
          content: "";
          position: absolute;
          bottom: -2px;
          width: 80%;
          height: 2px;
          background: black;
          -webkit-filter: blur(2px); /* Safari 6.0 - 9.0 */
          filter: blur(2px);
        }

        svg {
          opacity: 0;
        }

        img {
          opacity: 1;
        }
      }

      svg {
        width: 16px;
        height: 16px;
        transition: all 0.2s;
        color: white;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        opacity: 0;
        transition: all 0.2s;
      }
    }
  }

  .marker-cluster-custom-dots div .pox.recent {
    animation: blink 2s infinite;
  }

  .project-info-marker-custom .project-info-marker-img {
    animation: scaling 1s infinite;
  }

  .project-info-marker-img {
    height: 25px;
    width: 25px;
    border-radius: 50%;
  }

  .leaflet-bottom.leaflet-left {
    left: 10px;
    bottom: 60px;

    .leaflet-bar.leaflet-control {
      margin-bottom: 0 !important;
      margin-left: 0 !important;
      border: none !important;
      box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.12);

      a {
        width: 36px !important;
        height: 36px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px !important;
      }
    }
  }

  .navigator {
    position: absolute;
    z-index: 400;
    bottom: calc(80px + 60px);
    left: 10px;
    height: max-content;

    button {
      outline: none;
      border: none;
      background-color: white;
      color: #000;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.12);
      font-size: 16px;

      > svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .leaflet-top.leaflet-left,
  .leaflet-bottom.leaflet-right > .leaflet-control-attribution {
    z-index: 0 !important;
    display: none !important;
  }

  .leaflet-bottom.leaflet-right > .leaflet-draw {
    margin-right: 10px;
    margin-bottom: 200px;

    .leaflet-bar {
      border: none !important;
      box-shadow: 0 0 0.5em #1a1a1a;
    }
  }

  .info-btn {
    position: fixed;
    z-index: 401;
    bottom: 0;
    right: 0;
    margin-right: calc(72px + 10px);
    margin-bottom: 150px;
    width: 30px;
    height: 30px;
    background-color: black;
    border: 2px solid;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    border: none;
    line-height: 30px;
    padding: 0;
  }

  .info-modal > div {
    max-width: 480px;
  }

  @media (max-width: 578px) {
    .info-btn {
      bottom: unset;
      right: unset;
      top: calc(58px + 45px + 4px);
      left: 10px;
    }

    .leaflet-bottom.leaflet-left > .leaflet-control-zoom {
      display: none;
    }

    .leaflet-bottom.leaflet-right {
      right: unset;
      bottom: unset;
      top: calc(45px + 4px + 6px + 30px);
      left: 10px;

      > .leaflet-draw {
        margin-right: unset;
        margin-bottom: unset;
      }
    }

    .leaflet-popup-content .popup-content .popup-info {
      > div {
        font-size: 0.85em;
      }
    }

    .marker-custom {
      div.pox-marker.recent {
        &::before {
          width: 37.5px;
          height: 37.5px;
          top: 52%;

          &::after {
            width: 30px;
            height: 30px;
            top: 52%;
          }
        }
      }
    }

    .navigator {
      top: calc(32px + 45px + 4px + 5px);
      right: 5px;
      left: unset;

      button {
        width: 30px;
        height: 30px;

        > svg {
          width: 16px !important;
          height: 16px !important;
        }
      }
    }
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scaling {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes recent-scaling {
  0% {
    transform: scale(0) translate(-50%, -50%);
  }
  50% {
    transform: scale(1) translate(-50%, -50%);
  }
  100% {
    transform: scale(0) translate(-50%, -50%);
  }
}
