$primary-color: #042954;

.guided-tour {
  position: relative;
  z-index: 2000 !important;
  transform: none !important;

  .guided-tour-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
  }

  .guided-tour-wrapper {
    position: relative;
    z-index: 1;
    height: 100%;

    .guided-tour-content {
      position: absolute;
      top: calc(100% + 10px);
      right: 0;
      background-color: white;
      padding: 12px 16px;
      border-radius: 8px;
      max-width: 90vw;
      display: flex;
      flex-direction: column;
      gap: 10px;

      > div {
        button {
          outline: none;
          border: none;
          background-color: transparent;
          cursor: pointer;
        }
        &:first-child {
          display: flex;
          justify-content: flex-end;

          > button {
            color: #1c1b1f80;
            font-size: 20px;
            padding: 0;
            margin: 0;
            line-height: 0;
          }
        }
        &:nth-child(2) {
          white-space: nowrap;
          color: $primary-color;
          font-size: 14px;
          font-weight: 600;
        }
        &:last-child {
          display: flex;
          justify-content: flex-end;
          gap: 10px;

          > button {
            font-size: 12px;

            &:last-child {
              background-color: $primary-color;
              color: white;
              width: 86px;
              height: 30px;
              border-radius: 6px;
            }
          }
        }
      }

      @media (max-width: 578px) {
        padding: 8px 10px;
        border-radius: 6px;
        gap: 8px;

        > div {
          &:first-child {
            > button {
              font-size: 16px;
            }
          }
          &:nth-child(2) {
            font-size: 13px;
          }
          &:last-child {
            gap: 8px;

            > button {
              font-size: 11px;

              &:last-child {
                width: 76px;
                height: 24px;
                border-radius: 4px;
              }
            }
          }
        }
      }
    }
  }

  &.stage-2,
  &.stage-3 {
    .guided-tour-content {
      top: 50%;
      transform: translateY(-50%);
      right: calc(100% + 10px);
    }
  }

  &.stage-4 {
    .guided-tour-content {
      top: calc(-100% - 60px);
      right: 0;
    }
  }

  &.stage-5 {
    .guided-tour-wrapper {
      left: -50%;
    }

    .guided-tour-content {
      top: calc(-100% - 60px);
      right: 0;
    }
  }

  &.stage-6 {
    .guided-tour-content {
      top: 0;
      left: calc(100% + 10px);
      right: unset;
    }
  }

  @media (max-width: 578px) {
    &.stage-2.pox-type-filters {
      .guided-tour-wrapper {
        display: flex;
        height: 100%;
      }
      .guided-tour-content {
        top: calc(100% + 6px);
        left: 10px;
        right: unset;
        transform: unset;
      }
    }

    &.stage-3 {
      .guided-tour-content {
        top: calc(100% + 6px);
        left: 10px;
        right: unset;
        transform: unset;
      }
    }

    &.stage-4 {
      .guided-tour-content {
        top: calc(-100% - 40px);
        left: 0;
        right: unset;
      }
    }

    &.stage-5 {
      left: 0 !important;

      .guided-tour-wrapper {
        left: 10px;
      }

      .guided-tour-content {
        top: calc(-100% - 40px);
        right: unset;
      }
    }
  }
}
