$primary-color: #042954;
.message-page {
  position: relative;
  z-index: 1;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.2);
  }

  .content {
    position: fixed;
    bottom: calc(10px + 64px + 10px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 100%;
    height: 570px;
    max-width: 400px;
    max-height: calc(80% - 10px - 64px - 10px);
    background-color: #f0f2f5;
    border-radius: 12px;

    .inbox {
      height: 100%;
      display: flex;
      flex-direction: column;

      .header {
        > div {
          &:first-child {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px;

            > span {
              font-weight: 700;
              font-size: 18px;
              flex-grow: 1;
            }

            > button {
              line-height: 0;
              font-size: 14px;
              height: 32px;
              background-color: $primary-color;
            }
          }

          &.search-area {
            width: 100%;
            padding: 0 12px;

            .search-bar {
              position: relative;
              background-color: white;

              .icon {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 32px;
              }

              > input {
                width: 100%;
                height: 40px;
                padding-left: 32px;
                font-size: 14px;
                outline: none;
                border-radius: 8px;
              }
            }
          }
        }
      }

      .body {
        flex-grow: 1;
        overflow-y: auto;

        .empty {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-weight: 700;
          font-size: 18px;
        }

        .list-items {
          height: 100%;
          display: flex;
          flex-direction: column;
          gap: 4px;
          padding: 10px 0;

          .list-item {
            outline: none;
            border: none;
            cursor: pointer;
            line-height: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 4px;
            background-color: transparent;
            padding: 10px 12px;

            &:hover {
              background-color: #bebebe;
            }

            > div {
              flex-grow: 1;
              display: flex;
              align-items: center;
              gap: 4px;

              > img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
              }

              > span {
                font-size: 14px;
                font-weight: 500;
                flex-grow: 1;
                text-align: left;
                max-width: 280px;
              }
            }

            .unread {
              width: 10px;
              height: 10px;
              background-color: red;
              border-radius: 50%;
            }
          }
        }
      }
    }

    .chat-box {
      height: 100%;
      display: flex;
      flex-direction: column;

      .header {
        height: 57px;
        width: 100%;
        padding: 12px;
        border-bottom: thin solid gray;
        display: flex;
        justify-content: space-between;
        align-items: center;

        div:first-child {
          display: flex;
          gap: 6px;
          align-items: center;

          > span {
            font-weight: 700;
            font-size: 18px;
            flex-grow: 1;
            text-align: left;
            max-width: 250px;
          }

          > button {
            line-height: 0;
            font-size: 14px;
            height: 32px;
            background-color: $primary-color;
          }
        }

        .delete-btn {
          position: relative;

          > span {
            position: absolute;
            top: -8px;
            right: -8px;
            width: 16px;
            height: 16px;
            font-size: 10px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            padding: 0;
            line-height: 0;
            background-color: rgba($color: #c72222, $alpha: 0.8);
            color: #fff;
            border-radius: 4px;
          }
        }
      }

      .body {
        height: calc(100% - 57px);

        .message-body {
          height: calc(100% - 60px);
          padding: 5px;
          overflow-y: scroll;

          > *::-webkit-scrollbar {
            width: 5px;
          }
          > *::-webkit-scrollbar-track {
            background: #f1f1f1;
          }
          > *::-webkit-scrollbar-thumb {
            background: #888;
          }

          .messages-view {
            // height: 100%;
            // max-height: 100%;
            padding: 16px 4px;
            border: thin solid rgba(116, 116, 116, 0.5);
            border-radius: 8px;
            min-height: 100%;

            .message-item {
              display: flex;
              flex-direction: column;

              span.chat-day {
                width: 100%;
                font-size: 1em;
                font-weight: 700;
                text-align: center;
                margin: 24px 0;
                text-transform: uppercase;
                border-bottom: thin solid rgb(218, 218, 218);

                &:first-child {
                  margin-top: 0;
                }
              }

              .message {
                max-width: 80%;
                display: flex;
                gap: 5px;

                > button {
                  border: none;
                  outline: none;
                  font-size: 0.85em;
                  margin-bottom: 0.75em;
                  overflow: hidden;

                  > div {
                    background-color: rgb(102, 28, 28);
                    color: white;
                    border-radius: 0.75em;
                    border-top-left-radius: 0;
                  }

                  > span.checkbox {
                    display: none;
                  }

                  &.text,
                  &.deleted {
                    > div {
                      padding: 8px 12px;
                    }
                  }

                  &.deleted {
                    > div {
                      background-color: gray !important;
                      pointer-events: none;
                    }
                  }

                  &.file {
                    display: flex;

                    > div {
                      > div {
                        width: 100%;
                        background-color: rgb(201, 48, 48);
                        padding-right: 12px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        transition: 0.25s;

                        &:hover {
                          opacity: 0.85;
                        }

                        > span {
                          padding: 12px;
                          color: inherit;
                          flex-grow: 1;

                          width: 170px;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        }
                      }

                      > span.meta {
                        font-size: 0.75em;
                      }
                    }
                  }

                  &.selected {
                    display: flex;
                    align-items: flex-end;
                    gap: 2px;

                    > span.checkbox {
                      display: block;
                      width: 8px;
                      height: 8px;
                      background-color: rgb(224, 114, 114);
                      border-radius: 50%;
                    }
                  }
                }

                span.time {
                  font-size: 0.75em;
                  align-self: flex-end;
                }

                &.received {
                  > button {
                    padding: 0;
                    border-radius: 1em;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                  }

                  .selected {
                    flex-direction: row-reverse !important;
                  }
                }

                &.sent {
                  flex-direction: row-reverse;
                  align-self: flex-end;

                  > button {
                    padding: 0;
                    border-radius: 1em;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;

                    > div {
                      background-color: rgb(27, 27, 56);
                      border-top-left-radius: 0.75em;
                      border-top-right-radius: 0;

                      &.file > div {
                        background-color: rgb(57, 57, 179);
                      }
                    }
                  }
                }
              }
            }
          }

          > button {
            border: thin solid rgb(255, 255, 255);
            background-color: rgb(119, 119, 119);
            box-shadow: 3px 0px 5px rgba(116, 116, 116, 0.5);
          }
        }

        .enter-message {
          height: 60px;
          display: flex;
          justify-content: center;
          gap: 10px;
          background-color: rgba(23, 24, 92, 0.08);
          padding: 12px;

          > div {
            flex-grow: 1;
            display: flex;
            background-color: white;
            border-radius: 0.2rem;

            > input {
              flex-grow: 1;
              height: 36px;
              padding: 0 8px;
              outline: none;
              border: none;
              background-color: transparent;
            }

            button.file-upload {
              width: 36px;
              position: relative;
              z-index: 9;
              background-color: transparent;
              color: #a0a0a0;
              overflow: hidden;

              > input[type="file"] {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
              }
            }
          }

          button[type="submit"] {
            padding: 0 12px !important;
            background-color: $primary-color;
            position: relative;
            z-index: 10;
          }
        }
      }

      .message-modal {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 200;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(#000000, 0.25);

        .message-wrapper {
          padding: 10px;
          width: 100%;
          max-width: 320px;
          max-height: 75%;
          background: #c7c4c4;
          border: 1.5px solid #ffffff;
          border-radius: 12px;
          font-size: 1rem;
          color: #042954;
          backdrop-filter: blur(2px);
          padding: 30px 10px;
          overflow: auto;
        }
      }
    }

    @media (max-width: 578px) {
      bottom: calc(10px + 60px + 10px);
      height: calc(100%);
      left: 0;
      transform: unset;
      max-width: unset;
      border-radius: unset;
      max-height: calc(100% - 60px - 10px - 10px);
    }
  }

  .loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
