.webview-geofence {
  height: 100%;

  .webview-map {
    height: 100%;

    .leaflet-control-container {
      .leaflet-top.leaflet-right,
      .leaflet-bottom.leaflet-right .leaflet-control-attribution {
        display: none;
      }

      .leaflet-bottom.leaflet-right .leaflet-draw {
        margin: 0;
        position: fixed;
        top: calc(10px + 10px + 30px);
        right: 10px;

        .leaflet-bar {
          border: none !important;
          box-shadow: 0 0 0.5em #1a1a1a;
        }
      }
    }

    .geofence-info {
      .info-btn {
        position: fixed;
        z-index: 401;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
        background-color: black;
        border: 2px solid;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        cursor: pointer;
        border: none;
        line-height: 30px;
        padding: 0;
        box-shadow: 0 0 0.5em #1a1a1a;
      }
    }

    .geofence {
      bottom: 0 !important;
      background-color: rgba(#f0f2f5, 0.5);
      top: unset !important;
      max-height: unset !important;
    }
  }
}
