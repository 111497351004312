$primary-color: #042954;
$primary-lite-color: #4285f4;

.side-nav {
  width: 72px;
  height: 100%;
  background-color: $primary-color;

  .menu-wrapper {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    > button {
      font-size: 32px;
      color: white;
      padding: 0;
      line-height: 0;
    }

    .menu {
      position: fixed;
      z-index: 1000;
      bottom: 0;
      right: 0;
      height: calc(100% - 80px);
      width: 100%;
      display: flex;
      justify-content: flex-end;
      transition: all linear 200ms;

      .mask {
        position: absolute;
        background: rgba($color: #000000, $alpha: 0.1);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transition: all linear 50ms;
        opacity: 1;
      }

      .content {
        position: relative;
        z-index: 1;
        height: 100%;
        width: 300px;
        background-color: white;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        padding: 8px 16px;

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 4px;
          border-bottom: 2px solid $primary-color;
          padding: 12px 0;

          .user-section {
            flex-grow: 1;
            min-height: 40px;
            display: flex;
            align-items: center;
            gap: 6px;

            > img {
              width: 48px;
              height: 48px;
              border-radius: 50%;
            }

            > div {
              flex-grow: 1;
              display: flex;
              flex-direction: column;
              gap: 2px;

              > span {
                max-width: 180px;
                font-size: 14px;
                font-weight: 600;
              }

              > button.auth-btn {
                font-size: 10px;
                padding: 2px 8px;
                font-weight: 500;
                text-align: left;
                width: max-content;
                border-radius: 2px;
              }
            }
          }

          .close-btn {
            line-height: 0;
            font-size: 10px;
            background-color: $primary-color;
            color: white;
            padding: 8px;
          }
        }

        .body {
          .menu-nav {
            border-bottom: 2px solid $primary-color;
            padding: 12px 0;
            display: flex;
            flex-direction: column;
            gap: 10px;

            &:last-child {
              border-bottom: none;
            }

            .menu-nav-item {
              display: flex;
              gap: 10px;
              align-items: center;
              text-decoration: none;

              > svg {
                width: 24px;
                height: 24px;
              }

              > span {
                font-size: 14px;
                font-weight: 500px;
                color: #000000;
              }
            }
          }
        }
      }

      &.close {
        right: -100%;

        .mask {
          opacity: 0;
        }
      }
    }
  }

  .nav-btn {
    outline: none;
    cursor: pointer;
    border: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 64px;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 2px;
    background-color: transparent;
    color: white;
    transition: all ease-in;
    text-decoration: none;

    > svg {
      width: 24px;
      height: 24px;
    }

    > span {
      display: block;
      margin: 0;
      padding: 0;
      font-size: 13px;
    }

    &.my-poxes-link {
      > svg {
        width: 30px;
        height: 30px;
      }
    }

    &:hover {
      background-color: rgba($color: white, $alpha: 0.05);
    }

    &.active {
      background-color: $primary-lite-color;
    }
  }

  .top-section {
    padding-bottom: 5px;

    .pox-filters {
      .layer-tab {
        position: fixed;
        z-index: 401;
        top: 150px;
        right: 72px;
        background: #fff;
        position: absolute;
        padding: 8px;
        color: #000;
        box-shadow: 0 0 0.5em #1a1a1a;
        border-radius: 3px;
        width: 320px;
        height: auto;

        .layer-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .close-btn {
            color: black;
            cursor: pointer;
            padding: 0;
            font-size: 14px;
            outline: none;
            border: none;
            line-height: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
          }
        }

        .filter-header {
          color: rgb(43, 43, 43);
          padding: 0.25em;
          margin: -5px;
          margin-bottom: 0.25rem;
          cursor: pointer;
        }

        .accordion-header {
          .accordion-button {
            font-size: 0.85rem;
            padding: 0.375rem;

            &::after {
              height: 0.8rem;
              width: 0.8rem;
              background-size: 0.8rem;
            }
          }
        }

        .filter-body .accordion-body {
          display: grid;
          grid-template-columns: 1fr 1fr;
          padding: 0.5rem;
          column-gap: 10px;
          row-gap: 16px;
          padding: 10px;

          label.filter-entry {
            display: flex;
            align-items: center;
            gap: 8px;
            width: 100%;
            font-size: 0.85rem;
            font-weight: 500;
            margin: 0 !important;
            padding: 0 !important;
            line-height: 0;
            cursor: pointer;

            > input {
              height: 100%;

              &:checked {
                accent-color: $primary-color;
              }
            }
          }
        }
      }
    }

    .pay-type-filters {
      padding: 5px;

      .nav-switch {
        height: 84px;
        background-color: rgba($color: $primary-lite-color, $alpha: 0.24);
        display: grid;
        grid-template-rows: 1fr 1fr;
        border-radius: 12px;
        padding: 5px;

        button.nav-switch-btn {
          cursor: pointer;
          border: none;
          outline: none;
          background-color: transparent;
          color: #fff;
          font-size: 12px;
          font-weight: 600;
          transition: all ease-in 250ms;

          &.active {
            border-radius: 8px;
            background-color: $primary-lite-color;
          }
        }
      }
    }
  }

  .bottom-section {
    border-top: thin solid #a0a0a0;
  }

  @media (max-width: 480px) {
    position: fixed;
    // z-index: 1001;
    z-index: 401;
    left: 0;
    top: 58px;
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: space-between;

    .menu-wrapper {
      height: 58px;
      width: 40px;
      position: fixed;
      top: 0;
      right: 0;

      > button {
        font-size: 20px;
      }
    }

    .nav-btn {
      height: 100%;
      width: 44px;

      > svg {
        width: 16px;
        height: 16px;
      }

      > span {
        font-size: 8px;
      }

      &.my-poxes-link {
        > svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .top-section {
      display: flex;
      height: 100%;
      margin: 0;
      padding: 0;

      .pox-type-filters {
        display: flex;
        height: 100%;
      }

      .pox-filters {
        .layer-tab {
          right: unset;
          left: 0;
          top: 45px;
          width: 100%;

          .layer-header {
            margin-bottom: 12px !important;

            h5 {
              font-size: 16px;
            }
          }
        }
      }

      .pay-type-filters {
        position: fixed;
        z-index: -1;
        top: calc(58px + 45px + 4px);
        left: 5px;
        padding: 0;

        .nav-switch {
          height: 32px;
          width: 116px;
          background-color: rgba($color: $primary-color, $alpha: 1);
          display: grid;
          grid-template-rows: unset;
          grid-template-columns: 1fr 1fr;
          border-radius: 6px;

          button.nav-switch-btn {
            font-size: 10px;

            &.active {
              border-radius: 4px;
            }
          }
        }
      }
    }

    .bottom-section {
      border: none;
      display: flex;
    }
  }
}
