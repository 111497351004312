/* Custom scrillbar */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: rgb(88, 83, 83);
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(102, 96, 96);
}

.privacy-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  padding: 16px 4%;

  .privacy-section-wrapper {
    background-color: #ffffff;
    border-radius: 14px;
    font-size: 16px;

    .privacy-section-header {
      width: 100%;
      display: flex;
      align-items: center;

      .logo {
        max-height: 50px;
      }

      h1 {
        margin-left: -13%;
        flex-grow: 1;
        text-align: center;
        color: #042954;
        font-weight: 700;
        font-size: 1.5em;
      }

      button {
        position: fixed;
        top: 2%;
        right: 2%;
        width: 30px;
        height: 30px;
        border: thin solid #4274c4;
        border-radius: 50px;
        color: #4274c4;
        background: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .text-content {
      margin: 30px 0;
      color: #808080;
      font-size: 0.95em;
      line-height: 1.5;
      text-align: justify;

      b {
        color: black;
      }

      h3 {
        color: #042954;
        font-size: 1.15em;
        font-weight: 600;
        margin-bottom: 4px;
      }

      ul,
      ol {
        padding-left: 48px;

        li {
          margin: 8px 0;
          font-size: 0.9em;
        }
      }

      ol {
        list-style: upper-alpha;
      }
    }
  }

  @media (max-width: 578px) {
    padding: 4%;

    .privacy-section-wrapper {
      .privacy-section-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        .logo {
          margin-left: -1.5%;
        }

        h1 {
          margin-left: unset;
          margin-bottom: 0;
        }
      }

      .text-content {
        ul,
        ol {
          padding-left: 10%;
        }
      }
    }
  }
}
