.project-info-page {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	.mask {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.25);
		cursor: auto;
	}

	.content {
		z-index: 1;
		margin: auto;
		width: 90%;
		max-width: 480px;
		padding: 12px;
		border-radius: 12px;
		background-color: rgb(255, 255, 255);
		overflow: auto;

		.logo,
		.mobile-heading {
			display: none;
		}
	}

	@media (max-width: 578px) {
		.content {
			width: 100%;
			height: 100%;

			.logo {
				display: block;
				margin-bottom: 8px;
				margin-left: -4px;
				margin-top: -8px;

				img {
					width: 100px;
				}
			}

			.mobile-heading {
				display: flex;

				h1 {
					display: block;
					font-size: 1.5em;
					font-weight: 700;
					color: '#042954';
					margin-bottom: 0;
				}
			}

			.desktop-heading button {
				display: none;
			}

			h2,
			p,
			a {
				font-size: 0.9em;
			}
		}
	}
}
