.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .custom-modal-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.35);
  }

  .custom-contactmodal-content {
    position: relative;
    z-index: 1;
    background-color: rgba(255, 255, 255, 1);
    width: 360px;
    max-height: 85%;
    border: thin solid rgb(99, 99, 197);
    border-radius: 8px;

    .content {
      border-radius: inherit;
      background-color: inherit;

      .center-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .icons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;
      }
    }
  }
}

@media (max-width: 578px) {
  .custom-modal .custom-contactmodal-content {
    max-width: 90%;
    max-height: 90%;
  }

  .custom-modal .custom-contactmodal-content form label,
  .custom-modal .custom-contactmodal-content form input,
  .custom-modal .custom-contactmodal-content form select,
  .custom-modal .custom-contactmodal-content form textarea,
  .custom-modal .custom-contactmodal-content form button {
    font-size: 0.8em !important;
  }
}
