main.about-section {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 11;
	background: white;
	background-image: url('../../assets/img/background.png');
	background-position: center;
	background-repeat: no-repeat;
	overflow-y: auto;
	font-size: 0.9rem;

	div.mask {
		display: none;
	}

	div.content {
		> div {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-right: 1.5%;
			margin-bottom: 6px;

			img.logo {
				height: 50px;
			}

			.close-btn {
				outline: none;
				border: thin solid;
				border-radius: 50%;
				width: 24px;
				height: 24px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		section {
			h1 {
				color: #003069;
				font-weight: 600;
				margin-bottom: 16px;
				font-size: 1.5em;
			}

			p {
				margin: 8px 0;
				font-size: 0.9em;
			}

			p.text-small {
				font-size: 0.75em;
			}

			&.header {
				padding: 2%;

				p:not(.text-small) {
					color: #003069;
				}

				p.text-small {
					color: #939393;
				}
			}

			&.content {
				padding: 2%;

				.header {
					margin-bottom: 24px;

					p {
						color: #939393;
					}

					p.text-small {
						color: #003069;

						span {
							font-weight: 600;
						}
					}
				}

				.body {
					margin-bottom: 24px;

					.card {
						background-color: #f3f3f3;
						padding: 8px;
						border: none;
						min-height: 100px;
						margin: 10px 0;
						box-shadow: none;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 8px;

						p {
							max-width: 60%;
							text-align: center;
							font-size: 0.75em;
							color: #003069;
						}
					}
				}

				.footer {
					p {
						color: #939393;
					}
				}
			}
		}
	}

	@media (min-width: 758px) {
		background-color: transparent;
		font-size: 1rem;

		div.mask {
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.15);
		}

		div.content {
			z-index: 1;
			background-color: #fff;
			overflow-y: auto;
			position: absolute;
			width: 840px;
			height: 640px;
			max-width: 85%;
			max-height: 85%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 16px;
			box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
			background: white;
			background-image: url('../../assets/img/background.png');
			background-position: center;
			background-repeat: no-repeat;
			padding: 16px 8px;

			> div {
				display: flex;
				justify-content: center;
				align-items: center;
				padding-right: unset;

				img.logo {
					height: 60px;
				}

				.close-btn {
					position: absolute;
					top: 8px;
					right: 8px;
				}
			}

			section {
				h1 {
					font-size: 1.5em;
					text-align: center;
				}

				p {
					margin: 8px 0;
					text-align: center;
				}

				&.header {
					padding: unset;

					h1 {
						display: none;
					}
				}

				&.content {
					margin-top: 16px;
					padding: unset;

					.body {
						display: grid;
						grid-template-columns: 1fr 1fr 1fr;
						gap: 16px;
						padding: 0 24px;
						margin-bottom: 24px;

						.card {
							padding: 10px;

							p {
								max-width: 90%;
							}
						}
					}
				}
			}
		}
	}
}
