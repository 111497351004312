$primary-color: #042954;

.notification {
  position: relative;
  z-index: 401;

  > button {
    position: relative;

    > span.unread {
      position: absolute;
      top: -8px;
      right: -8px;
      width: 16px;
      height: 16px;
      font-size: 10px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;
      line-height: 0;
      background-color: rgba($color: #000, $alpha: 0.2);
      color: #fff;
      border-radius: 4px;
    }
  }

  .custom-modal-wrapper {
    position: absolute;
    top: 100%;
    right: 0;

    > button.mask {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.1);
      border: none;
      outline: none;
      cursor: default;
    }

    > .child-wrapper {
      position: relative;
      z-index: 1;
      background-color: #fff;
      padding: 4px;
      border-radius: 8px;
      min-width: 360px;

      .content {
        padding-bottom: 4px;
        margin-bottom: 4px;
        border-bottom: thin solid #5c5c5c;

        .header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 8px;
          padding-bottom: 4px;

          > span {
            font-weight: 700;
            font-size: 16px;
          }
        }

        .body {
          max-height: 360px;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          gap: 6px;
          padding: 4px 0;
          border-top: thin solid rgba($color: #000, $alpha: 0.2);

          .notification-item {
            display: flex;
            flex-direction: column;
            gap: 1px;
            font-size: 12px;
            padding: 4px 8px;
            border-bottom: thin solid rgba($color: #000, $alpha: 0.2);
            // box-shadow: 0px 1px 0px 1px rgba($color: #000000, $alpha: 0.2);

            .notification-type {
              font-weight: 700;
            }

            > div {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 8px;

              > span.date {
                min-width: 56px;
                max-width: 56px;
                width: 56px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }

      .footer-section {
        width: 100%;
        font-size: 12px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 4px 0;
        font-weight: 500;

        button {
          outline: none;
          padding: 2px 6px;
          margin: 0;
          border: thin solid;
          background-color: transparent;
          cursor: pointer;
          font-weight: 700;
          color: $primary-color;
          border-radius: 4px;
        }
      }
    }
  }

  @media (max-width: 578px) {
    .custom-modal-wrapper {
      > .child-wrapper {
        padding: 4px 2px;
        min-width: unset;
        width: 75vw;

        .content {
          .header {
            > span {
              font-size: 14px;
            }

            > button {
              font-size: 10px !important;
            }
          }

          .body {
            .notification-item {
              font-size: 10px;
              padding: 2px 8px;
            }
          }
        }
      }
    }
  }
}
