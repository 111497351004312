.custom-modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 11;
	width: 100%;
	height: 100%;

	.custom-modal-mask {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.35);
	}

	.custom-modal-content {
		bottom: 60px;
		left: 50%;
		transform: translateX(-55%);
		background-color: rgba(255, 255, 255, 1);
		width: 360px;
		max-height: 85%;
		position: absolute;
		border: thin solid rgb(99, 99, 197);
		border-radius: 8px;

		&::before {
			content: '';
			position: absolute;
			z-index: -1;
			bottom: -25px;
			left: 50%;
			width: 35px;
			height: 35px;
			background-color: inherit;
			transform: rotate(45deg) translateX(-50%);
			border: inherit;
		}

		.content {
			border-radius: inherit;
			background-color: inherit;

			.center-btn {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

@media (max-width: 578px) {
	.custom-modal .custom-modal-content {
		width: 360px;
		max-width: 90%;
		max-height: 90%;
		transform: translateX(-50%);
		overflow: unset;
	}

	.custom-modal .custom-modal-content h1 {
		font-size: 1em !important;
		font-weight: 700;
	}

	.custom-modal .custom-modal-content::before {
		left: 30%;
	}

	.custom-modal .custom-modal-content form label,
	.custom-modal .custom-modal-content form input,
	.custom-modal .custom-modal-content form select,
	.custom-modal .custom-modal-content form button {
		font-size: 0.8em !important;
	}
}
