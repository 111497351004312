.geofence {
  position: fixed;
  top: 80px;
  left: 1%;
  z-index: 400;
  width: 420px;
  max-height: 90%;
  background: #f0f2f5;
  color: #042954;
  border-radius: 8px;
  font-size: 1rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 480px) {
    top: unset;
    left: 0;
    bottom: calc(50px + 10px);
    width: 100%;
    max-height: 360px;

    .content {
      overflow-x: scroll;

      &::-webkit-scrollbar {
        height: 5px;
      }

      > ul {
        display: flex;
        gap: 16px;
        list-style: none;
        padding: 0 16px 8px 16px !important;
        margin: 0 !important;
      }

      .geofence-card {
        width: 200px;
        gap: 12px !important;
        align-items: start !important;
        padding: 12px 16px !important;

        .color {
          min-width: 20px;
          margin-top: 10px;
        }

        > div {
          width: 100%;
          display: block !important;

          .info {
            flex-grow: unset;
            margin-bottom: 8px;
          }

          .actions {
            max-width: unset;
            width: 100%;
            display: grid !important;
            grid-template-columns: 1fr 1fr;
            gap: 4px !important;

            > button {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 4px;

              span.mobile-only {
                display: block;
                margin-bottom: -2px;
                font-size: 0.65rem !important;
              }
            }
          }
        }
      }
    }
  }

  .content {
    max-height: 360px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #042954;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #04295466;
    }

    .geofence-card {
      .color {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: inline-block;
        border: 2px solid;
      }

      .info {
        display: flex;
        flex-direction: column;
      }

      .actions {
        min-width: 60px;

        > button span.mobile-only {
          display: none;
        }
      }
    }
  }

  .pagination > li > * {
    font-size: 0.75rem;
  }
}
