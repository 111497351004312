$primary-color: #042954;

.guided-tour-init-modal {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.2);

  .content {
    background-color: white;
    width: 532px;
    min-height: 430px;
    max-width: 90%;
    max-height: 90%;
    border-radius: 18px;
    padding: 40px 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:first-child {
        gap: 10px;

        > img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }

        > span {
          font-size: 24px;
          color: $primary-color;
          font-weight: 600;
          text-align: center;
        }
      }
      &:nth-child(2) {
        span {
          font-size: 18px;
          font-weight: 500;
          color: #a0a0a0;
          text-align: center;
        }
      }
      &:last-child {
        gap: 16px;
        width: 100%;

        button {
          outline: none;
          border: none;
          width: 100%;
          cursor: pointer;
          font-size: 14px;
          font-weight: 500;

          &:first-child {
            background-color: $primary-color;
            color: white;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
          }
          &:last-child {
            background-color: transparent;
            color: #a0a0a0;
          }
        }
      }
    }

    @media (max-width: 578px) {
      padding: 6% 5%;
      min-height: 300px;

      > div {
        &:first-child {
          gap: 6px;

          > img {
            width: 60px;
            height: 60px;
          }

          > span {
            font-size: 18px;
          }
        }
        &:nth-child(2) {
          > span {
            font-size: 16px;
          }
        }
        &:last-child {
          gap: 10px;

          > button {
            font-size: 12px;
          }
        }
      }
    }
  }
}
