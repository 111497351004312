$primary-color: #042954;
$primary-lite-color: #4274c4;

.control-bottom-right {
  position: absolute;
  bottom: 10px;
  // right: calc(72px + 5px);
  right: 10px;
  // z-index: 400;
  font-size: 1rem;

  .legend-container p {
    text-transform: uppercase;
    font-weight: 600;
    color: #3b3b3b;
  }

  .legend-entry {
    display: flex;
    flex-direction: column;
  }

  .legend-header {
    display: flex;
    justify-content: flex-end;

    .visual-item {
      background-color: #bebfc05b;
      color: $primary-color;
      cursor: pointer;
      padding: 0.25rem 1rem;
      text-align: center;
      text-transform: capitalize;
      transition: all ease-in 300ms;
      font-weight: 500;
      font-size: 14px;

      &.active {
        background-color: $primary-lite-color;
        color: white;
        border-radius: 8px;
      }
    }
  }

  .legend-items {
    padding: 0.85em 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 12px;
  }

  .legend-items .bold {
    font-weight: 700;
  }

  .legend-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;
    padding: 0.45em 0.75em;
    line-height: 16px;
    margin: 0;
  }

  .legend-item div {
    width: 100%;
  }

  .legend-container .unit-section {
    position: absolute;
    right: 0;
    bottom: -10px;
  }
}

@media (max-width: 578px) {
  /* layer-control */
  .control-bottom-right {
    left: 10px;
    right: unset;
    bottom: calc(54px + 10px + 10px);

    .legend-header {
      justify-content: flex-start;

      .visual-item {
        font-size: 12px;
      }
    }

    .legend-items {
      padding: 0.5em 0;
      font-size: 10px;
    }

    .legend-container .unit-section {
      left: 0;
      right: unset;
    }
  }
}
