$primary-color: #042954;

.bottom-nav {
  position: absolute !important;
  //   z-index: 400;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);

  .bottom-nav-content {
    width: 360px;
    height: 64px;
    padding: 0 16px;
    background-color: $primary-color;
    border-radius: 10px;

    nav {
      height: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      > a {
        text-decoration: none;

        > div {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 1px;
          transition: all ease-in 300ms;

          > .icon {
            position: relative;

            .badge {
              position: absolute;
              top: -5px;
              right: -10px;
              width: unset;
              height: unset;
              padding: 0;
              font-weight: 700;
            }

            svg {
              width: 21px;
              height: 21px;

              path {
                fill: white;
                fill-opacity: 0.5;
              }
            }
          }

          > span {
            font-size: 12px;
            color: white;
            opacity: 0.5;

            &.active {
              opacity: 1;
            }
          }

          &.active {
            border-top: 2px solid #fbbc05;

            > .icon svg path {
              fill-opacity: 1;
            }

            > span {
              opacity: 1;
            }
          }
        }
      }
    }

    @media (max-width: 480px) {
      .bottom-nav-content {
        width: calc(100% - 20px);
        height: 50px;
        padding: 0 10%;

        nav {
          gap: 8px;

          > a > div {
            > .icon svg {
              width: 16px;
              height: 16px;
            }
            > span {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
