.floating-details-wrapper {
  position: fixed;
  top: 80px;
  left: 1%;
  z-index: 400;
  width: 420px;
  max-width: 100%;
  max-height: 90%;
  background: #f0f2f5;
  color: #042954;
  border-radius: 8px;
  font-size: 1rem;
  display: flex;
  flex-direction: column;

  .content {
    max-height: 360px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #042954;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #04295466;
    }
  }

  .badge {
    font-size: 0.65em;
  }

  .text-truncate {
    max-width: 180px;
  }

  .filter-toggle::after {
    content: none;
  }

  .custom-radio-wrapper {
    padding: 0.1rem 0.65rem;
    min-height: 30px;
  }

  label.form-label {
    font-size: 0.85rem;
  }

  .pagination > li > * {
    font-size: 0.75rem;
  }

  @media (max-width: 480px) {
    left: 0;
    max-height: 100%;
    height: 100%;
    top: 0;

    .content {
      max-height: 70vh;
    }

    .card-body {
      padding: 0.5rem !important;
      gap: 0.5rem !important;
    }
  }
}
