.store-links {
    position: fixed;
    z-index: 401;
    bottom: 10px;
    left: 10px;
    display: flex;
    gap: 8px;

    @media (max-width: 578px) {
        display: none;
        a > svg {
            height: 32px;
            width: auto;
        }
    }
}