.saved-notes-page {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	background-color: rgba(#000000, 0.45);
    display: flex;
	align-items: center;
	justify-content: center;

	* {
		&::-webkit-scrollbar {
			width: 8px;
			height: 8px;
		}
		&::-webkit-scrollbar-track {
			background: #f1f1f1;
		}
		&::-webkit-scrollbar-thumb {
			background: #888;
		}
		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
	}

	.mask {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.content {
		position: relative;
		z-index: 10;
		background: #F0F2F5;
		width: 100%;
		height: 100%;
		max-width: 960px;
		max-height: 90%;
		padding: 0 2%;
        border-radius: 12px;

		@media (max-width: 480px) {
			max-width: unset;
			max-height: unset;
			border-radius: unset;
		}
    }

	.filter-toggle::after {
        content: none;
    }
}