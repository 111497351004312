.pox-info-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(#000000, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;

  * {
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .content {
    position: relative;
    z-index: 10;
    overflow-y: auto;
    background-color: #f0f2f5;
    width: 100%;
    height: 100%;
    padding: 0 2.5% 16px 2.5%;

    .btn-close {
      display: none;
    }

    .imageContainer {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .mainImage {
        height: 150px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }

      .slideImages {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        img {
          flex-basis: 30%;
          height: 60px;
          border-radius: 8px;
          cursor: pointer;
          border: 3px solid #042954;
        }
      }
    }

    @media (min-width: 480px) {
      .imageContainer {
        flex-direction: row-reverse;
        justify-content: space-between;
        height: 200px;

        .mainImage {
          height: 100%;
          flex-basis: 80%;
        }

        .slideImages {
          height: 100%;
          flex-direction: column;
          justify-content: space-between;

          img {
            width: 120px;
            height: 60px;
          }
        }
      }
    }

    .content-heading {
      > div {
        h1 {
          font-weight: 700;
          font-size: 17px;
          color: #1f1f1f;
        }

        .type {
          max-width: 70px;
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
          gap: 4px;
          padding: 5px 12px;
          color: white;
          border-radius: 4px;
          cursor: default;

          background: linear-gradient(
            280.96deg,
            #3975ea 0%,
            rgba(57, 117, 234, 0.5) 100%
          );
        }
        .type-desktop {
          display: none;
        }

        @media (min-width: 480px) {
          .type-mobile {
            display: none;
          }
          .type-desktop {
            display: flex;
          }
        }

        .address {
          font-size: 12px;
          font-weight: 500;
          color: #a0a0a0;
        }

        .btn-icons {
          border-radius: 4px;
          background-color: #0429545d;
          cursor: pointer;
          color: #042954;
          outline: none;
          border: none;
          height: 28px;
          width: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all ease-in-out 250ms;

          &:hover {
            background-color: #042954dd;
            color: #fff;
          }
        }

        .share-modal {
          position: fixed;
          z-index: 5;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          background-color: rgba(#000000, 0.25);

          .content {
            background-color: #ffffff;
            z-index: 1;
            border-radius: 12px;
            width: 90%;
            max-width: 420px;
            height: max-content;
            margin-top: 10%;
            position: relative;
            overflow-x: hidden;

            button {
              font-size: 0.8em;
            }

            .btn-close {
              position: absolute;
              top: 4%;
              right: 2.5%;
            }

            .heading {
              padding: 0;

              h5 {
                flex-grow: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 1.125em;
                font-weight: 700;
              }
            }

            .socials {
              max-width: 240px;
              margin: auto;

              button {
                font-weight: 500;
                width: 80px;

                svg {
                  height: 24px;
                  width: 24px;
                }
              }
            }
          }
        }
      }
    }
  }

  .content-body {
    margin: 20px 0;
    display: flex;
    flex-direction: column;

    .toggle-detail {
      background-color: white;
      display: flex;
      gap: 16px;
      align-self: center;
      padding: 6px 10px;
      border-radius: 6px;
      margin-bottom: 16px;

      button.tab-btn {
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 4px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .status-actions {
      > span {
        font-size: 10px;
      }

      button.status-btn {
        outline: none;
        border: none;
        cursor: pointer;
        font-size: 11px;
        font-weight: 600;
        height: 24px;
        border-radius: 4px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .message-modal {
      width: 100%;
      height: 100%;
      position: fixed;

      top: 0;
      left: 0;
      z-index: 20;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(#000000, 0.25);

      .message-wrapper {
        padding: 10px;
        width: 320px;
        max-height: 75%;
        background: #d4d4d4;
        border: 1.5px solid #ffffff;
        border-radius: 12px;
        z-index: 400;
        font-size: 1rem;
        color: #042954;
        backdrop-filter: blur(2px);
        padding: 30px 10px;
        overflow: auto;
      }
    }

    .description {
      display: flex;
      flex-direction: column;
      margin: 20px 0;

      span {
        font-size: 14px;
        font-weight: 600;
      }

      > div {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .info-section {
      flex-grow: 1;
      min-height: 150px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .main-info {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .prediction-chart-heading {
        margin-bottom: 16px;

        > span {
          font-weight: 700;
          font-size: 14px;
          display: block;
          margin-bottom: 8px;
        }

        > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          > form.filter-form {
            display: flex;
            height: 24px;

            input {
              font-size: 13px;
              width: 80px;
              border: thin solid #9c9c9c;
              outline: none;
              padding: 2px 4px;
            }

            button {
              height: 100%;
              outline: none;
              border: none;
              cursor: pointer;
              font-size: 10px;
              background-color: #042954;
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          > span.selected-years-range {
            font-size: 12px;
            font-weight: 600;
            color: #a0a0a0;
          }
        }
      }

      .prediction-chart {
        height: 240px;

        tspan {
          font-size: 12px;
          font-weight: 600;
        }

        .chart-custom-tooltip {
          font-size: 12px;
          background-color: #fff;
          padding: 2px 4px 0 4px;
          border-radius: 4px;

          .tooltip-label {
            font-weight: 500;
          }
        }
      }
    }

    .shortdesc {
      span {
        font-size: 10px;
        &.desc {
          font-size: 14px;
        }
      }

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .owner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    border-radius: 8px;
    background-color: white;

    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }

    .user-name {
      font-weight: 700;
      font-size: 14px;
    }

    .user-status {
      font-size: 12px;
      color: #a0a0a0;
    }

    .owner-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
    }

    .send-message {
      outline: none;
      cursor: pointer;
      border: none;
      background-color: #042954;
      color: #fff;
      border-radius: 4px;
      padding: 6px 24px;
      font-size: 14px;
      font-weight: 500;
      transition: all 250ms ease-in-out;

      &:hover {
        background-color: #0429545d;
      }

      &.mobile {
        display: none;
        padding: 4px;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        background-color: #34a853;

        &:hover {
          background-color: #34a8535d;
        }
      }
    }
  }

  @media (max-width: 380px) {
    .owner {
      .user-name {
        font-size: 12px;
      }

      .owner-actions {
        gap: 5px;
      }

      .send-message {
        &:not(.mobile) {
          display: none;
        }
        &.mobile {
          display: flex;
        }
      }
    }
  }

  @media (min-width: 578px) {
    .content {
      width: 750px;
      height: max-content;
      padding-bottom: 16px;
      max-width: 95%;
      max-height: 95%;
      border-radius: 10px;

      .btn-close {
        position: absolute;
        z-index: 10;
        top: 2%;
        right: 2%;
        width: 20px;
        height: 20px;
        border: thin solid #000;
        border-radius: 50px;
        color: #4274c4;
        background: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .content-heading {
        > div {
          .share-modal .content {
            .heading h5 {
              font-size: 0.95em;
            }

            button {
              font-size: 0.75em;
            }
          }
        }
      }

      .shortdesc {
        span {
          font-size: 12px;
          font-weight: 600;

          &.desc {
            font-size: 16px;
            font-weight: 500;
          }
        }

        img {
          width: 16px;
          height: 16px;
        }
      }

      .status-actions {
        > span {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }

    .content-body {
      flex-direction: row;
      gap: 30px;

      .description {
        span {
          font-size: 12px;
        }
      }

      .toggle-detail {
        flex-direction: column;
        padding: 10px;
        align-self: flex-start;
        margin-bottom: 0;
      }

      .message-modal {
        .message-wrapper {
          width: 400px;
        }
      }
    }
  }
}
