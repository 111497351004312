$primary-color: #042954;

.statistics {
  position: fixed !important;
  top: calc(80px + 10px);
  left: 10px;

  button.view-btn {
    outline: none;
    border: none;
    cursor: pointer;
    transition: all ease-in 300ms;
    width: 36px;
    height: 36px;
    border-radius: 5px;
    padding: 4px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #000;
    background-color: #fff;

    > span {
      display: none;
      opacity: 0;
      font-weight: 500;
      font-size: 12px;
      line-height: 0;
    }

    &.active,
    &:hover {
      width: auto;

      > span {
        display: block;
        opacity: 1;
      }
    }
  }

  .poxes_stats {
    background-color: #fff;
    border-radius: 6px;
    min-width: 164px;
    max-width: 90%;

    ul {
      list-style: none;
      padding: 6px;

      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10px;
        font-weight: bold;
        font-size: 0.75em;
        gap: 6px;

        > span {
          &.value {
            background-color: $primary-color;
            color: #fff;
            height: 32px;
            min-width: 32px;
            display: block;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        &:not(:first-child) {
          padding-top: 6px;
        }

        &:not(:last-child) {
          padding-bottom: 6px;
          border-bottom: thin solid #ffffff;
        }
      }
    }

    .overlay-loader {
      position: absolute;
      top: 0;
      left: 0;
      display: grid;
      place-items: center;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 578px) {
    top: calc(58px + 32px + 45px + 4px + 5px);

    button.view-btn {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      padding: 2px 6px;
      box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.12);

      > span {
        font-size: 10px;
      }
    }

    .poxes_stats {
      font-size: 0.65rem;
      min-width: 132px;

      ul {
        padding: 6px 4px;

        li {
          padding: 0 4px;

          > span {
            &.value {
              height: 22px;
              min-width: 22px;
            }
          }

          &:not(:first-child) {
            padding-top: 4px;
          }

          &:not(:last-child) {
            padding-bottom: 4px;
          }
        }
      }
    }
  }
}
