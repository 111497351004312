.pox-image {
  border-radius: 8px;
  aspect-ratio: 5/4;
  width: 82px;
  min-width: 82px;
  max-width: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000018;

  > img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.actions {
  width: 52px;
  min-width: 52px;
  max-width: 52px;
}
