.custom-radio-wrapper {
    position: relative;
    max-width: max-content;
    padding: 0.1rem 1.5rem;
    border-radius: 0.25rem;
    border: 1px solid #c5c5c5;
    background-color: #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 34px;
    max-height: 34px;

    &.active {
        background-color: #4285F4;
        border-color: #4285F4;
        
        > label {
            color: #ffffff
        }
    }

    > label {
        color: #414141;
        margin: 0;
        position: relative;
        z-index: 1;
        pointer-events: none;    
    }

    > input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        margin: 0 !important;
        float:none !important;
    }
}

.input-group.reverse {
    flex-direction: row-reverse;

    > button {
        margin-left: 0px;
        margin-right: -1px;
        border-top-right-radius: 0;
        border-top-left-radius: 0.25rem !important;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0.25rem !important;
    }

    > input {
        border-top-left-radius: 0;
        border-top-right-radius: 0.25rem !important;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0.25rem !important;
    }
}